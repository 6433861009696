<div class="form-group position-relative mb-0 cursor-pointer" dropdown #dropdown="bs-dropdown"
     (onHidden)="onHidden()" (onShown)="onShown()" [insideClick]="multiple" [isDisabled]="disabled || readonly">
  <div class="form-default" [class.is-valid]="value && !errors">
    <div class="btn-group border-lighter border-bottom w-100" dropdownToggle *ngIf="multiple; else singleTemplate"
      [class.disabled]="disabled || readonly">
      <div class="flex-grow-1 overflow-hidden dropdown-toggle" style="min-height: 32px" [class.filled]="isValue">
        <div class="w-100 overflow-auto">
          <span *ngFor="let item of value; let idx = index" class="badge badge-light px-1">
          {{item[display]}}
            <button class="btn btn-link p-0" (click)="delValue(idx)">
            <i class="icon icon-cont-remove-sm"></i>
          </button>
        </span>
        </div>
      </div>
      <label class="form-control-label">{{placeholder}}</label>
      <button type="button" class="btn d-flex align-items-center flex-grow-0 pr-0">
        <i class="icon icon-cont-caret bg-secondary"></i>
      </button>
    </div>
    <ng-template #singleTemplate>
      <div class="btn-group border-lighter border-bottom w-100" [class.disabled]="disabled || readonly" dropdownToggle>

        <button type="button" class="btn btn-default dropdown-toggle text-left text-ellipsis px-0" style="margin-bottom: -1px"
                [class.filled]="isValue">{{isValue ? (value | displayPipe:display) : ''}}
        </button>
        <label class="form-control-label">{{placeholder}}</label>
        <button type="button" class="btn d-flex align-items-center flex-grow-0 pr-0">
          <i class="icon icon-cont-caret bg-secondary"></i>
        </button>
      </div>
    </ng-template>
    <div class="invalid-feedback">
      <div *ngIf="errors?.required">Required field</div>
    </div>
  </div>
  <div class="dropdown-menu dropdown-menu-bottom min-w-100" *dropdownMenu role="menu">
    <div class="form-group m-0" (click)="$event.stopPropagation();">
      <input type="text" class="form-control helper-control px-3" placeholder="Start typing..."
            [formControl]="searchInputControl">
    </div>
    <ng-container [ngSwitch]="multiple">
      <div class="dropdown-wrap" *ngSwitchCase="'multi'">
        <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
        <button class="dropdown-item" type="button" *ngIf="value && !required"
                (click)="writeValue([]); $event.stopPropagation();">Clear</button>

        <button class="dropdown-item" type="button" *ngFor="let item of items | valuesPipe:value:element"
                (click)="addValue(item); $event.stopPropagation();">
          {{item | displayPipe:display}}<span class="text-right text-secondary ml-auto" *ngIf="extra">{{item[extra]}}</span>
        </button>
        <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
      </div>
      <!--
      <div class="dropdown-wrap" *ngSwitchCase="'group'">
        <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
        <div class="dropdown-group" *ngFor="let group of items; let idx = index">
          <div class="dropdown-header text-dark">{{group.title}}</div>
          <div class="dropdown-item border-0 p-0" *ngFor="let item of group.children | filterPipe:queryStr; let i = index">
            <div class="form-group form-check bf-checkbox d-flex align-items-center mb-0" [class.checked]="dropdownItem.checked">
              <input type="checkbox" class="form-check-input" id="item_{{idx}}_{{i}}" #dropdownItem>
              <label class="form-check-label d-flex align-items-center" for="item_{{idx}}_{{i}}"
                     (click)="addValue(item)">
                {{item[display]}}<span class="text-right text-secondary ml-auto" *ngIf="extra">{{item[extra]}}</span>
              </label>
            </div>
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
      </div>
      -->
      <div class="dropdown-wrap" *ngSwitchDefault>
        <button class="dropdown-item" type="button" *ngIf="value && !required"
                (click)="clearValue(); $event.stopPropagation();">Clear</button>
        <ng-content></ng-content>
        <button class="dropdown-item" type="button" *ngFor="let item of items"
                (click)="writeValue(item)">
          {{item | displayPipe:display}}<span class="text-right text-secondary ml-auto" *ngIf="extra">{{item[extra]}}</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #headerTemplate>
  <ng-content select="[header]"></ng-content>
</ng-template>
<ng-template #footerTemplate>
  <ng-content select="[footer]"></ng-content>
</ng-template>
