import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, Self, SimpleChanges } from '@angular/core';
import {FormControl, NgControl} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

import { API_BASE, PER_PAGE } from '@shared/helpers/constants';
import { SelectComponent } from '@shared/modules/forms/select/select.component';

import { QueryService } from "@shared/services/query.service";

@Component({
  selector: 'form-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss']
})
export class TypeaheadComponent extends SelectComponent implements OnChanges, OnDestroy, OnInit {

  @Input() object: string;
  @Input() params: object;
  @Input() value: any;
  @Input() preloadValue: boolean;
  @Input() items = [];
  @Output() eventSearch: EventEmitter<any> = new EventEmitter();

  findSubject = new Subject<any>();
  searchInputControl = new FormControl('');
  initialItems = [];
  private prevUrl: string;
  private _subs: Subscription = new Subscription();

  onChange: any = (value) => {
    if (this.preloadValue) {
      this.fetchItem(value);
    }
  }

  constructor(@Self() @Optional() control: NgControl,
              private http: HttpClient,
              public queryService: QueryService) {
    super(control, queryService);

    if (control) {
      control.valueAccessor = this;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this._subs.add(
      this.findSubject
        .pipe(
          debounceTime(500),
          distinctUntilChanged((prev, curr) => {
            return JSON.stringify(prev?.search) === JSON.stringify(curr?.search);
          }),
        ).subscribe(params => {
        this.eventSearch.emit(params);
        this.fetchList(params);
      })
    );
    this._subs.add(
      this.searchInputControl.valueChanges.subscribe(value => {
        if (value && value.startsWith(' ')) {
          value = value.trim();
          this.searchInputControl.setValue(value, {emitEvent: false});
        }
        this.findSubject.next({search: value});
      })
    );

    if (this.multiple && !this.value) {
      this.value = [];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => this.writeValue(this.value));
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  addValue(value: any) {
    this.writeValue([...this.value, value]);
  }

  delValue(index: number) {
    this.value.splice(index, 1);
    this.writeValue(this.value);
    if (!this.value || this.value.length === 0) {
      this.items = [...this.initialItems];
    } else {
      this.items = this.initialItems.filter(el => this.value.some(val => val.named !== el.named));
    }
  }

  writeValue(value?: any) {
    if (value === 'clearValue') {
      this.clearValue();
    } else {
      if (value) {
        this.value = value;
        this.onChange((this.isString && value[this.element]) ? value[this.element] : value);
      }
    }
  }

  clearValue() {
    this.value = null;
    this.onChange(null);
  }

  onShown() {
    const currUrl = this.object + JSON.stringify(this.params);
    if (currUrl !== this.prevUrl) {
      this.prevUrl = currUrl;
      this.fetchList(this.params);
    }
  }

  fetchList(params = {}, queryValue?) {
    if (!this.object) {
      return null;
    }

    params = Object.assign(params, { limit: PER_PAGE, list: true }, this.params);

    if (this.value) {
      const values = Array.isArray(this.value)
        ? this.value.map(item => item[this.element]).join(',')
        : this.value[this.element];
      if (!['currencies'].includes(this.object)) {
        params = Object.assign(params, {exclude: values});
      }
    }
    if (this.object === 'suppliers/supplier_types' || this.object === 'currencies') {
      delete params['limit'];
    }
    if (queryValue && ['suppliers', 'clients'].includes(this.object)) {
      params = {...params, ...{ id : queryValue}};
    }
    if (queryValue && ['currencies'].includes(this.object)) {
      params = {...params, ...{ [this.display] : queryValue}};
    }
    this.http.get(`${API_BASE}/${this.object}/`, { params })
      .subscribe(response => {
          console.log(response['data']);
          this.items = response['data'];
          if (this.multiple) {
            this.initialItems = [...this.items];
          }
          if (queryValue) {
            this.findValue(response['data'], queryValue);
          }
        }
      );
  }

  fetchItem(value?: string) {
    const params: any = { code: value, item: true };
    this.http.get(`${API_BASE}/${this.object}/`, { params })
      .pipe(
        map(response => response['data'])
      )
      .subscribe(response => {
        this.value = { name: 'Austria', code: 'AT' };
      });
  }

  setFilterValueFromQuery() {
    if (this.filter) {
      const queryValue = this.queryService.getValueFromQueryByName(this.control.name);
      const shortValue = this.value;
      if (queryValue || shortValue) {
        if (this.items && this.items.length > 0) {
          this.findValue(this.items, queryValue || shortValue);
        } else {
          this.fetchList(this.params, queryValue || shortValue);
        }
      }
    }
  }

  findValue(items, value) {
    let key;
    if (['bank', 'currency'].includes(this.control.name.toString())) {
      key = this.display;
    } else {
      key = this.element;
    }
    if (value?.length > 0) {
      let searchedValue;
      if (this.multiple === 'multi') {
        value = value.split(',');
        searchedValue = items.filter(el => value.includes(el[key]));
      } else {
        searchedValue = items.find(el => el[key] === value);
      }
      if (searchedValue) {
        this.writeValue(searchedValue);
      }
    }
  }

}
