<div class="form-group mb-0" [class.d-none]="type === 'hidden'" [class.disabled]="readonly" >
  <div class="form-default" [class.is-valid]="control?.value && !errors">
    <div class="position-relative" [class.input-group]="icon || showPasswordEyeIcon">
      <div class="top-label text-secondary" *ngIf="topLabel">{{topLabel}}</div>
      <input class="form-control form-control-default position-relative"
             [ngClass]="modify"
             [class.filled]="control?.value || control?.value === 0"
             [attr.id]="uniqueId"
             [attr.type]="type"
             [attr.min]="min"
             [attr.max]="max"
             [attr.minlength]="minlength"
             [attr.maxlength]="maxlength"
             [readonly]="readonly"
             [formControl]="control"
             [mask]="controlMask"
             [suffix]="maskSuffix"
             [prefix]="maskPrefix"
             [thousandSeparator]="maskThousandSeparator"
             [dropSpecialCharacters]="maskDropSpecialCharacters"
             [specialCharacters]="maskSpecialCharacters"
             (blur)="eventBlur.emit($event)"
             (keyup)="writeValue(elementRef.value, true)"
             [attr.placeholder]="label"
             #elementRef>
      <label *ngIf="!label" class="form-control-label d-flex justify-content-between" [attr.for]="uniqueId">
        {{placeholder}}
      </label>
      <div class="input-group-append" *ngIf="icon || showPasswordEyeIcon">
        <!--<button class="d-flex btn pr-0" type="button" tabindex="-1">-->
        <!--<i class="icon" [ngClass]="icon"></i>-->
        <!--</button>-->
        <span class="input-group-text py-0" *ngIf="icon">
          <i class="icon bg-secondary"
             [ngClass]="icon"
          >
          </i>
        </span>
        <span class="input-group-text py-0" *ngIf="showPasswordEyeIcon">
          <i class="icon bg-secondary"
             [ngClass]="'icon-eye-' + (type === 'text' ? 'show' : 'hide')"
             (click)="changeShowHideIcon();">
          </i>
        </span>
      </div>

      <!--      todo refactor-->
      <div class="invalid-feedback">
        <div *ngIf="errors?.required">Required field</div>
        <div *ngIf="errors?.unique">Should be unique</div>
        <div *ngIf="errors?.email">Invalid mail format</div>
        <div *ngIf="errors?.onlyRu">Only Russian letters are allowed</div>
        <div *ngIf="errors?.onlyEn">Only English letters are allowed</div>
        <div *ngIf="errors?.onlyNum">Only numbers allowed</div>
        <div *ngIf="errors?.onlyStr">Only letters are allowed</div>
        <div *ngIf="errors?.minlength">Minimum {{errors?.minlength?.requiredLength}} characters</div>
        <div *ngIf="errors?.maxlength">Maximum {{errors?.maxlength?.requiredLength}} characters</div>
        <div *ngIf="errors?.min">Minimum value {{errors?.min?.min}}</div>
        <div *ngIf="errors?.max">Maximum value {{errors?.max?.max}}</div>
        <div *ngIf="errors?.minDate">Minimum date: {{errors?.minDate | date:'dd/MM/yyyy'}}</div>
        <div *ngIf="errors?.maxDate">Maximum date: {{errors?.maxDate | date:'dd/MM/yyyy'}}</div>
        <div *ngIf="errors?.date">Invalid date format</div>
        <div *ngIf="errors?.incorrect">Invalid field value</div>
        <div *ngIf="errors?.pattern">Invalid field value</div>
        <div *ngIf="errors?.invalidCardNumber">Invalid card number</div>
        <div *ngIf="errors?.notSame">Passwords must be same</div>
      </div>
    </div>
  </div>
</div>
